import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout path={location.pathname} title="קטע מספר 12: מקיבוץ ארז לחוף זיקים">
      <SectionContent title="קטע מספר 12: מקיבוץ ארז מפגש נחל שקמה לחוף זיקים">
        <div>
          <p>
            <strong>אורך הקטע: 10 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה &#8211; בינונית</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            מתחנת ההחלפה ממשיכים לכיוון כללי צפון צפון מזרח. לאחר כ – 1 ק&quot;מ
            פונים ימינה וחצים את נחל שקמה. לאחר חציית הנחל פונים שמאלה לכיוון
            כללי מערב על גבי דרך 'נוף שקמה'. נרוץ על הדרך עוד כ-2.1 ק&quot;מ עד
            לפניה שמאלה שבסמוך לה מבנה עתיק של תחנת רכבת, ומצפון לה חורשת
            אקליפטוסים. נפנה שמאלה בכיוון כללי מערב, נחצה בזהירות את כביש 4 בין
            יד מרדכי לבין נתיב העשרה, ונמשיך עם הדרך הראשית (דרך אספלט) עד לצמת
            הכניסה האחורית ליד מרדכי, שם נפנה שמאלה ונמשיך כ – 2 ק&quot;מ עד
            למפגש עם דרך לבנה משמאל (כ – 200 מטר לאחר בית העלמין של קיבוץ
            כרמיה). לאחר שפנינו שמאלה נמשיך לרוץ על הדרך הלבנה עוד 1400 מטר עד
            למפגש דרכים (מימין כרמיה, מלפנים שטח חממות), נפנה שמאלה לדרך שבהמשך
            עולה על הסוללה התוחמת את מאגר 'שקמה' מצפון כ-1.8 ק&quot;מ, עד שנגיע
            למפגש דרכים מימין שער ברזל כחול ומשמאל שלט 'מאגר שקמה'. נעבור דרך
            השער (מימין) ומיד נפנה שמאלה על גבי דרך האספלט עד למפגש עם הכביש
            הגישה לחוף זיקים (כ-600 מטר), נפנה שמאלה ונרוץ לאורך השוליים
            השמאליים של הכביש כ- 200 מטר עד לצמת T בה נפנה ימינה ונמשיך לרוץ
            בשולי הכביש עד לשער הסיום בחוף הים.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: מגמת ירידה מתונה
          </p>
          <p>
            <strong>מסלול</strong>: כביש אספלט, דרך לבנה, דרך עפר.
          </p>
          <p>
            <strong>בטיחות</strong>: ערנות בחציית הכביש שבין יד מרדכי לנתיב
            העשרה, ערנות לרכבים הנוסעים בכביש הגישה לחוף זיקים.
          </p>
          <p>
            <strong>שער הסיום</strong>: בחוף זיקים
          </p>
        </div>
        <div>
          <p>
            <iframe
              className="alltrails"
              src="https://www.alltrails.com/widget/map/wed-02-feb-2022-19-03-c831e9b?u=m"
              width="100%"
              height="400"
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              title="קטע מספר 12: מקיבוץ ארז מפגש נחל שקמה לחוף זיקים"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
